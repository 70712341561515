import { ErrorSVG } from "../src/components/common";
import React from "react";

function Error() {
    return (
        <div className={'error-page'}>
            <div className="error-svg">
                <ErrorSVG />
            </div>
            <p align="center">
                Content Not Found (404)
            </p>
        </div>
    )
}

export default Error;